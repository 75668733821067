<template>
    <div class="information-container section-offset" v-if="post">
        <div class="tabs-content">
            <div>
                <div class="information-cards information-cards--offset">
                    <div class="news-card">
                        <div class="news-card-header news-card__header">
                            <h3 class="news-card__title" v-if="$i18n.locale === 'ru'">{{ post.name_ru }}</h3>
                            <h3 class="news-card__title" v-else>{{ post.name }}</h3>
                            <div class="news-card__category">
                                <span>{{ formattedDate(post.created) }}</span>
                            </div>
                        </div>
                        <div class="news-card__image">
                            <img v-if="$i18n.locale === 'ru'" :src="post.img_ru" />
                            <img v-else :src="post.img" />
                        </div>
                        <div v-if="$i18n.locale === 'ru'" v-html="post.body_ru"></div>
                        <div v-else v-html="post.body"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="information-tabs">
            <div v-for="category in categories.slice(0, 2)" :key="category.id" class="information-tab">
                <router-link
                    v-if="$i18n.locale === 'ru'"
                    :to="{ name: 'showCategory', params: { id: category.id.toString() } }"
                >
                    {{ category.name_ru }}
                </router-link>
                <router-link v-else :to="{ name: 'showCategory', params: { id: category.id.toString() } }">
                    {{ category.name }}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { $api } from "@/plugins/Axios"

export default {
    props: ["postId"],
    data() {
        return {
            categories: [],
            post: {},
        }
    },
    mounted() {
        $api.get("/categories").then((response) => {
            this.categories = response.data.data
        })

        $api.get(`/posts/${this.postId}`).then((response) => {
            this.post = response.data.data
        })
    },
    methods: {
        formattedDate(date) {
            return this.$moment(date * 1000).format("DD MMMM YYYY")
        },
    },
}
</script>
